import { API } from "api";
import moment from "moment";
import { useQuery } from "react-query";
import { useStore } from "store/RootStore";
import { IChannel, IHeroChannel, IPlayback } from "type";
import { domain } from "util/domain";

export const useChannels = (extraParams: any = {}, queryParams: any = {}) => {
  const { data, isLoading } = useQuery(
    ["GetChannels", extraParams],
    () =>
      API.get("/channel", {
        params: {
          is_online: true,
          ...extraParams,
        },
      }).then((response) => response.data as IChannel[]),
    {
      refetchOnWindowFocus: false,
      ...queryParams,
    }
  );
  return {
    data,
    isLoading,
  };
};

export const usePlaybackChannels = () => {
  const { data, isLoading } = useQuery(
    ["GetPlaybackChannels", { is_customized: true }],
    () =>
      API.get("/channel", {
        params: {
          is_customized: true,
        },
      }).then((response) => response.data as IChannel[]),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data,
    isLoading,
  };
};

export const useHeroChannels = () => {
  const { data, isLoading } = useQuery(
    ["GetHeroChannels", domain],
    () =>
      API.get("/hero_channel", {
        params: {
          domain
        },
      }).then((response) => response.data as IHeroChannel[]),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: data?.sort((a: IHeroChannel, b: IHeroChannel) =>
      a.order > b.order ? 1 : -1
    ),
    isLoading,
  };
};

export const useFavoriteChannels = () => {
  const { authStore } = useStore();
  const { data, isLoading, refetch } = useQuery(
    "GetFavoriteChannels",
    () =>
      API.get("/channel/favorites").then(
        (response) => response.data as IChannel[]
      ),
    {
      refetchOnWindowFocus: false,
      enabled: authStore.isLoggedIn,
    }
  );
  return {
    data,
    isLoading,
    refetch,
  };
};

export const useChannelDetailByName = (name: string) => {
  const { data, isLoading } = useQuery(
    ["GetChannelByName", name],
    () =>
      API.get(`/channel/${name}`).then((response) => {
        const channel = {
          ...response.data,
          playbacks: response.data?.playbacks?.sort(
            (a: IPlayback, b: IPlayback) =>
              moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1
          ),
        } as IChannel;
        return channel;
      }),
    {
      enabled: !!name,
    }
  );
  return {
    data,
    isLoading,
  };
};
