import {
  Box,
  Flex,
  StatGroup,
  StatLabel,
  Stat as ChakraStat,
  StatNumber,
  StatHelpText,
  StatArrow,
  Text,
  Center,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  HStack,
  Image,
} from "@chakra-ui/react";
import { useChannelDetailByName } from "hooks/useChannel";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useStore } from "store/RootStore";
import { StatItem } from "./StatItem";
import { UserTable } from "./UserTable";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import {
  Document,
  Page,
  Text as PdfText,
  Image as PdfImage,
  PDFViewer,
  View,
} from "@react-pdf/renderer";
import { useQuery } from "react-query";
import { API } from "api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Data Analytics",
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const audiencesData: any = {
  Jan: 2,
  Feb: 8,
  Mar: 3,
  Apr: 7,
  May: 2,
  Jun: 5,
  Jul: 3,
  Aug: 5,
  Sep: 6,
  Oct: 10,
  Nov: 9,
  Dec: 2,
};

const playbacksData: any = {
  Jan: 0,
  Feb: 2,
  Mar: 0,
  Apr: 3,
  May: 5,
  Jun: 6,
  Jul: 2,
  Aug: 8,
  Sep: 4,
  Oct: 5,
  Nov: 2,
  Dec: 4,
};

export const data = {
  labels,
  datasets: [
    {
      label: "Live Show Audiences",
      data: labels.map((label) => audiencesData[label]),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Barrage",
      data: labels.map(() => 0),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
    {
      label: "Playbacks Play Count",
      data: labels.map((label) => playbacksData[label]),
      borderColor: "rgb(96, 214, 131)",
      backgroundColor: "rgba(96, 214, 131, 0.5)",
    },
  ],
};

type Props = {};

export const Stat = (props: Props) => {
  const { authStore } = useStore();
  const { data: channel } = useChannelDetailByName(authStore.user?.name);
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [image, setImage] = React.useState<string>("");
  const chartRef = React.useRef<any>(null);
  const handlePreviewOpen = () => setPreviewOpen(true);
  const handlePreviewClose = () => setPreviewOpen(false);

  useEffect(() => {
    const image = chartRef?.current?.toBase64Image();
    setImage(image);
  }, [chartRef]);

  return (
    <Box w="full" h="full" display="flex" flexDirection="column" overflow='scroll' paddingY="20px">
      <Flex paddingY="20px">
        <StatGroup w={"full"}>
          <StatItem
            query={{
              where: {
                category: "channel_view",
                channel_id: channel?.id,
              },
              select: {
                id: true,
              },
            }}
            label={<FormattedMessage id="dashboard.data.audiences" />}
          />

          <StatItem
            query={{
              where: {
                category: "chat",
                channel_id: channel?.id,
              },
              select: {
                id: true,
              },
            }}
            label={<FormattedMessage id="dashboard.data.barrage" />}
          />

          <StatItem
            query={{
              where: {
                category: "playback_view",
                channel_id: channel?.id,
              },
              select: {
                id: true,
              },
            }}
            label={<FormattedMessage id="dashboard.data.playbacks" />}
          />
        </StatGroup>
      </Flex>

      <Center height={380}>
        <Line options={options} data={data} ref={chartRef} />
      </Center>

      <Box padding="20px">
        <Text mb="20px">前五观众</Text>
        <UserTable />
      </Box>
      <Center>
        <Button onClick={handlePreviewOpen}>Export Report</Button>
      </Center>
      <Modal isOpen={previewOpen} onClose={handlePreviewClose} size="3xl">
        <ModalOverlay />
        <ModalContent height="80vh">
          <ModalHeader>Preview Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PDFViewer width="100%" height="100%">
              <Document>
                <Page
                  size="A4"
                  style={{
                    flexDirection: "column",
                    backgroundColor: "#E4E4E4",
                    alignItems: "center",
                    paddingVertical: 20,
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "50px",
                    }}
                  >
                    <PdfText style={{ fontSize: "30px" }}>
                      Anylatics Report of 2023 for Tony
                    </PdfText>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "20px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginLeft: 100,
                      fontSize: "16px",
                    }}
                  >
                    <PdfText>Live Show Audiences: 62</PdfText>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "20px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginLeft: 100,
                      fontSize: "16px",
                    }}
                  >
                    <PdfText>Barrage Count: 0</PdfText>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "20px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginLeft: 100,
                      fontSize: "16px",
                    }}
                  >
                    <PdfText>Playbacks play count: 41</PdfText>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "30px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginLeft: 100,
                      fontSize: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <PdfText>Top 5 Audiences:</PdfText>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "20px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginLeft: 100,
                      fontSize: "12px",
                    }}
                  >
                    <PdfText>tony (212.107.29.67)</PdfText>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "20px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginLeft: 100,
                      fontSize: "12px",
                    }}
                  >
                    <PdfText>handshands (45.120.2.236)</PdfText>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "20px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginLeft: 100,
                      fontSize: "12px",
                    }}
                  >
                    <PdfText>Guest (113.110.178.180)</PdfText>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "20px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginLeft: 100,
                      fontSize: "12px",
                    }}
                  >
                    <PdfText>Guest (212.107.29.72)</PdfText>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "20px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginLeft: 100,
                      fontSize: "12px",
                    }}
                  >
                    <PdfText>hands (45.120.1.21)</PdfText>
                  </View>
                  <PdfImage
                    src={image}
                    style={{ width: "500px", height: "250px" }}
                  />
                </Page>
              </Document>
            </PDFViewer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
