import {
  Flex,
  Box,
  Image,
  useColorModeValue,
  chakra,
  AspectRatio,
  Link,
  Center,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import { IChannel } from "screen/Home";

export const ChannelCard = ({ data }: { data: IChannel }) => {
  return (
    <Flex
      w={{ base: "45%", md: "280px" }}
      h={{ base: "195px", md: "300px" }}
      alignItems="center"
      justifyContent="center"
    >
      <Link
        textDecoration={"none"}
        h="100%"
        w="100%"
        as={RouterLink}
        to={data.channelPath ?? `/channel/${data.broadcasterName}`}
      >
        <Box
          h="100%"
          bg={useColorModeValue("white", "gray.800")}
          w="100%"
          borderWidth="1px"
          rounded="lg"
          shadow="lg"
          position="relative"
          cursor={"pointer"}
        >
          <AspectRatio maxW="100%" ratio={16 / 9}>
            <Image
              filter="grayscale(0)"
              fallback={
                <Center bg="gray.200" fontSize={"xl"}>
                  {data.title}
                </Center>
              }
              src={data.screenshot}
              alt={`Picture of ${data.broadcasterName}`}
              roundedTop="lg"
            />
          </AspectRatio>

          <Box p={{ base: 2, md: 6 }}>
            <Flex mt="1" justifyContent="space-between" alignContent="center">
              <Box
                fontSize={{ base: "sm", md: "xl" }}
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
              >
                {data.title}
              </Box>

              <chakra.div fontSize={{ base: "sm", md: "l" }} display={"flex"}>
                <FormattedMessage id={`pages.home.${(data.category.name || '').toLowerCase()}`}/>
              </chakra.div>
            </Flex>

            <Flex justifyContent="space-between" alignContent="center">
              <Flex>{data.broadcasterName}</Flex>
              <Box
                fontSize={{ base: "sm", md: "xl" }}
                color={useColorModeValue("gray.800", "white")}
              >
                {data.heat}
              </Box>
            </Flex>
          </Box>
        </Box>
      </Link>
    </Flex>
  );
};
