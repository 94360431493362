import { useStore } from "store/RootStore";
import { Navigate } from "react-router";
import { Box, Button, HStack, Input, Text, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useState } from "react";

export const Manage = observer(() => {
  const { authStore } = useStore();
  const [user, setUser] = useState({
    email: "",
    name: "",
    password: "",
  });
  const [channel, setChannel] = useState({
    email: "",
    name: "",
    password: "",
  });

  const handleUserSignup = async () => {
    try {
      await authStore.boardcastRegister(user);
      setUser({
        email: "",
        name: "",
        password: "",
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  if (!authStore.isAdmin) {
    return <Navigate to="/" />;
  }
  return (
    <VStack padding="20px" gap={2}>
      <VStack
        backgroundColor="gray.100"
        w="100%"
        rounded="md"
        padding={6}
        alignItems="flex-start"
        gap={1}
      >
        <Text>Sign up</Text>
        <HStack
          w="full"
          alignItems="center"
          justifyContent="space-between"
          gap={3}
        >
          <VStack alignItems="flex-start" flex={1}>
            <Text>Name</Text>
            <Input
              value={user.name}
              onChange={(event) =>
                setUser({ ...user, name: event.target.value })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Email</Text>
            <Input
              value={user.email}
              onChange={(event) =>
                setUser({ ...user, email: event.target.value })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Password</Text>
            <Input
              value={user.password}
              onChange={(event) =>
                setUser({ ...user, password: event.target.value })
              }
              background="white"
            />
          </VStack>
        </HStack>
        <Button onClick={handleUserSignup} background="white">Sign Up Boardcaster</Button>
      </VStack>
      <VStack
        backgroundColor="gray.100"
        w="100%"
        rounded="md"
        padding={6}
        alignItems="flex-start"
        gap={1}
      >
        <Text>Create Channel</Text>
        <HStack
          w="full"
          alignItems="center"
          justifyContent="space-between"
          gap={3}
        >
          <VStack alignItems="flex-start" flex={1}>
            <Text>Name</Text>
            <Input
              value={user.name}
              onChange={(event) =>
                setUser({ ...user, name: event.target.value })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Email</Text>
            <Input
              value={user.email}
              onChange={(event) =>
                setUser({ ...user, email: event.target.value })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Password</Text>
            <Input
              value={user.password}
              onChange={(event) =>
                setUser({ ...user, password: event.target.value })
              }
              background="white"
            />
          </VStack>
        </HStack>
        <Button onClick={handleUserSignup} background="white">Sign Up Boardcaster</Button>
      </VStack>
    </VStack>
  );
});
