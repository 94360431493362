import React from "react";
import { Box, Container, Flex } from "@chakra-ui/react";
import { ChannelCard } from "component/ChannelCard";
import { HomeHeroPlayer } from "./HomeHeroPlayer";
import { usePlaybackChannels } from "hooks/useChannel";

export interface IChannel {
  id: string;
  screenshot: string;
  broadcasterName: string;
  is_online: boolean;
  title: string;
  category: { name: string };
  heat: number;
  channelPath: string;
}

export const Home = () => {
  const { data: channels = [], isLoading } = usePlaybackChannels();

  if (isLoading) return null;
  
  return (
    <Flex flexDir={"column"} w="full">
      <Container maxW="container.xl">
        <HomeHeroPlayer />
      </Container>
      <Container maxW="container.xl" display="flex" justifyContent="center">
        <Flex
          margin="0 auto"
          flexWrap={"wrap"}
          gap={"20px"}
          direction={"row"}
          paddingTop="50px"
          justifyContent={"space-between"}
        >
          {channels.map((channel: IChannel) => (
            <ChannelCard key={channel.id} data={channel} />
          ))}
          <Box w={{ base: "45%", md: "280px" }} h="0"></Box>
          <Box w={{ base: "45%", md: "280px" }} h="0"></Box>
          <Box w={{ base: "45%", md: "280px" }} h="0"></Box>
          <Box w={{ base: "45%", md: "280px" }} h="0"></Box>
        </Flex>
      </Container>
    </Flex>
  );
};
