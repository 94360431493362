import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";

type Props = {};

const QA = [
  {
    question: "dashboard.help.howToStart.title",
    answer: "dashboard.help.howToStart.description",
  },
  {
    question: "dashboard.help.cannotStart.title",
    answer: "dashboard.help.cannotStart.description",
  },
  {
    question: "dashboard.help.ban.title",
    answer: "dashboard.help.ban.description",
  },
];

export const Help = (props: Props) => {
  const { formatMessage } = useIntl();
  return (
    <Flex h="full" w={"100%"} flexDirection="column">
      <Accordion background={"white"}>
        {QA.map((item, index) => (
          <AccordionItem key={index}>
            <h2>
              <AccordionButton>
                <Box fontWeight={"bold"} flex="1" textAlign="left">
                  {index + 1}.{formatMessage({ id: item.question })}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {formatMessage({ id: item.answer })}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Flex>
  );
};
