import React, { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { useChannelDetailByName } from "hooks/useChannel";
import { useEvents } from "hooks/useEvent";
import {
  Box,
  Center,
  HStack,
  useColorModeValue,
  VStack,
  Image,
  AspectRatio,
  Text,
  Heading,
  IconButton,
  Button,
} from "@chakra-ui/react";
import { GrEdit } from "react-icons/gr";
import { RiDeleteBin5Line } from "react-icons/ri";
import { EventService } from "module/auth/service/EventService";
import { IEvent } from "type";
import EditEventModal from "./EditEventModal";
import DeleteEventModal from "./DeleteEventModal";
import CreateEventModal from "./CreateEventModal";
import moment from "moment";
import { useQueryClient } from "react-query";
import { FormattedMessage } from "react-intl";

export const Event = observer(() => {
  const { authStore } = useStore();
  const queryClient = useQueryClient();
  const bgColor = useColorModeValue("white", "gray.700");
  const { data: channel } = useChannelDetailByName(authStore.name);
  const {
    data: { activeEvent = {}, events = [] },
  } = useEvents(authStore.name);

  const [selectedEvent, setSelectedEvent] = useState<IEvent>();
  const [isSelectedEventActive, setIsSelectedEventActive] = useState(false);
  const [editEventModalOpen, setEditEventModalOpen] = useState(false);
  const [deleteEventModalOpen, setDeleteEventModalOpen] = useState(false);
  const [createEventModalOpen, setCreateEventModalOpen] = useState(false);

  const handleEditEventClick = useCallback((item: IEvent) => {
    setSelectedEvent(item);
    setEditEventModalOpen(true);
  }, []);

  const handleEditEventModalClose = useCallback(() => {
    setIsSelectedEventActive(false);
    setSelectedEvent(undefined);
    setEditEventModalOpen(false);
  }, []);

  const handleDeleteEventClick = useCallback((item: IEvent) => {
    setSelectedEvent(item);
    setDeleteEventModalOpen(true);
  }, []);

  const handleDeleteEventModalClose = useCallback(() => {
    setIsSelectedEventActive(false);
    setSelectedEvent(undefined);
    setDeleteEventModalOpen(false);
  }, []);

  const handleCreateEventClick = useCallback(() => {
    setCreateEventModalOpen(true);
  }, []);

  const handleCreateEventModalClose = useCallback(() => {
    setCreateEventModalOpen(false);
  }, []);

  const handleSetEventActive = useCallback(
    async (event: IEvent) => {
      await EventService.setEventToActive(channel?.id ?? "", event.id);
      await queryClient.invalidateQueries("GetChannelEvents");
    },
    [channel?.id, queryClient]
  );

  const renderEvent = useCallback(
    (event: any, isActive = false) => {
      return (
        <HStack
          key={event?.id}
          width="100%"
          bg={bgColor}
          rounded={"xl"}
          boxShadow={"lg"}
          p={10}
          cursor="pointer"
        >
          <Box w="40%" marginRight="16px">
            <AspectRatio maxW="100%" ratio={16 / 9}>
              <Image
                fallback={
                  <Center bg="gray.200" fontSize={"xl"}>
                    9DLive
                  </Center>
                }
                src={channel?.screenshot}
                alt={`Picture of ${channel?.user?.name}`}
              />
            </AspectRatio>
          </Box>
          <VStack display="flex" alignItems="flex-start">
            <Heading as="h2" size="lg">
              {event?.title}
            </Heading>
            <Text>{event?.description}</Text>
            <Text>
              {moment(event?.start_at).format("YYYY-MM-DD HH:mm:ss")} -{" "}
              {moment(event?.end_at).format("YYYY-MM-DD HH:mm:ss")}
            </Text>
            <HStack mb="16px">
              <IconButton
                aria-label="edit"
                icon={<GrEdit />}
                fontSize="24px"
                variant="ghost"
                onClick={() => {
                  handleEditEventClick(event);
                  setIsSelectedEventActive(isActive);
                }}
              />
              <IconButton
                aria-label="delete"
                icon={<RiDeleteBin5Line />}
                fontSize="24px"
                variant="ghost"
                onClick={() => {
                  handleDeleteEventClick(event);
                  setIsSelectedEventActive(isActive);
                }}
              />
            </HStack>
            {!isActive && (
              <Button
                colorScheme="blue"
                onClick={() => {
                  handleSetEventActive(event);
                }}
              >
                <FormattedMessage id="dashboard.liveShow.setOngoing" />
              </Button>
            )}
          </VStack>
        </HStack>
      );
    },
    [
      bgColor,
      channel?.screenshot,
      channel?.user?.name,
      handleDeleteEventClick,
      handleEditEventClick,
      handleSetEventActive,
    ]
  );

  return (
    <VStack
      spacing={4}
      w={"full"}
      maxW={"80%"}
      p={10}
      alignItems="flex-start"
      height="100%"
      overflow="scroll"
    >
      <HStack
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading as="h1" size="xl" textAlign="start" padding="16px 0px">
          {activeEvent ? (
            <FormattedMessage id="dashboard.liveShow.underwayLive" />
          ) : (
            <FormattedMessage id="dashboard.liveShow.noUnderwayLive" />
          )}
        </Heading>
        <Button colorScheme="blue" onClick={handleCreateEventClick}>
          <FormattedMessage id="dashboard.liveShow.new" />
        </Button>
      </HStack>
      {activeEvent && renderEvent(activeEvent, true)}
      {events.length > 0 && (
        <Heading as="h1" size="xl" textAlign="start" padding="16px 0px">
          <FormattedMessage id="dashboard.liveShow.others" />
        </Heading>
      )}
      {events.map((event: IEvent) => renderEvent(event))}
      {editEventModalOpen && selectedEvent && (
        <EditEventModal
          isActive={isSelectedEventActive}
          event={selectedEvent}
          onClose={handleEditEventModalClose}
        />
      )}
      {deleteEventModalOpen && selectedEvent && (
        <DeleteEventModal
          isActive={isSelectedEventActive}
          event={selectedEvent}
          onClose={handleDeleteEventModalClose}
        />
      )}
      {createEventModalOpen && (
        <CreateEventModal
          channelId={channel?.id ?? ""}
          onClose={handleCreateEventModalClose}
        />
      )}
    </VStack>
  );
});

export default Event;
